import React, { useMemo, memo, useEffect, useState, useRef } from "react";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { useSelector } from "react-redux";
import { useWidget } from "../../contexts";
import {
  getCoordinatesSelector,
  getMapStateSelector,
} from "../../store/selectors/mapSelectors";
import { getSelectedRegionSelector } from "../../store/selectors/regionSelectors";
import { EMapState } from "../../utils/models/EMapState";
import { useMediaQuery, useTheme } from "@mui/material";
import useWindowDimensions from "../../utils/hooks/customHooks/useWindowDimensions";
import RegionMapState from "./mapStates/regionMapState";
import CityMapState from "./mapStates/cityMapState";
import SectionMapState from "./mapStates/sectionMapState";
import BeachMapState from "./mapStates/beachMapState";
import { ICoordinates } from "../../store/reducers/mapReducer";
import { getMobileViewExpandMenuSelector } from "../../store/selectors/mainAppSelectors";
import AllCitiesMapState from "./mapStates/allCitiesMapState";
import RowMapState from "./mapStates/rowMapState";
import SectionBeachChairsMapState from "./mapStates/sectionBeachChairsMapState";
import { getVendorSelector } from "../../store/selectors/vendorSelectors";
import BeachMapStateForSection from "./mapStates/beachMapStateForSection";

function getMobileMapPadding(mapState: EMapState) {
  switch (mapState) {
    case EMapState.ALL_CITIES:
    case EMapState.REGION:
    case EMapState.CITY:
    case EMapState.SECTION:
    case EMapState.ROWS:
      return -10;
    case EMapState.BEACH:
      return -20;
    default:
      return 0;
  }
}

function getMapPadding(mapState: EMapState) {
  switch (mapState) {
    case EMapState.REGION:
    case EMapState.BEACH:
    case EMapState.ROWS:
      return 100;
    case EMapState.CITY:
      return 200;
    case EMapState.SECTION:
      return 300;
    default:
      return 0;
  }
}

const Maps = memo(() => {
  const [mapBounds, setMapBounds] = useState<google.maps.LatLngBounds>();
  const mapState = useSelector(getMapStateSelector);
  const mapStateRef = useRef<EMapState>(mapState);
  mapStateRef.current = mapState;
  const [zoom, setZoom] = useState(0);
  const coordinates = useSelector(getCoordinatesSelector);
  const coordinatesRef = useRef<ICoordinates>(coordinates);
  coordinatesRef.current = coordinates;

  const region = useSelector(getSelectedRegionSelector);

  const mobileViewExpandMenu = useSelector(getMobileViewExpandMenuSelector);

  const [center, setCenter] = useState({
    lat: coordinates.lng,
    lng: coordinates.lat,
  });

  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { height, width } = useWindowDimensions();
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: encodeURIComponent(
      "AIzaSyBWGae4Fpv0p0n-llM6Qysj4r_CvvhzIkw"
    ),
    language: "DE",
  });

  const [map, setMap] = useState<google.maps.Map>();
  const { isWidgetActive } = useWidget();
  const configuration = useSelector(getVendorSelector)?.configuration;

  useEffect(() => {
    if (!map || !mapBounds) {
      return;
    }

    // Fit map bounds to mobile view
    if (isMobileScreen && width < 900) {
      map.fitBounds(mapBounds, getMobileMapPadding(mapState));
      return;
    }

    // Fit map bounds to desktop view
    if (mapState === EMapState.ALL_CITIES || !region?.id) {
      map.setZoom(8);
    } else {
      map.fitBounds(mapBounds, getMapPadding(mapState));
    }
    if (
      (mapState === EMapState.SECTION || mapState === EMapState.ROWS) &&
      configuration?.beachChairView === "map"
    ) {
      map.fitBounds(mapBounds, getMapPadding(mapState));
      //map.setZoom(19);
    }
  }, [
    mapBounds,
    mapState,
    map,
    isMobileScreen,
    width,
    region?.id,
    configuration,
  ]);

  const mapHeight = useMemo(() => {
    if (isMobileScreen) {
      return height - (mobileViewExpandMenu ? 232 : 175);
    }
    return isWidgetActive ? "100vh" : `calc(100vh - 50px)`;
  }, [isMobileScreen, height, mobileViewExpandMenu, isWidgetActive]);

  const onLoad = (map: google.maps.Map) => {
    const listener = google.maps.event.addListener(map, "idle", () => {
      const zoom = map.getZoom();
      if (zoom && zoom > 10) {
        if (mapStateRef.current === EMapState.ALL_CITIES) {
          map.setZoom(8);
        } else if (mapStateRef.current === EMapState.REGION) {
          map.setZoom(11);
        } else {
          map.setZoom(15);
        }
        setCenter({
          lat: coordinatesRef.current.lng,
          lng: coordinatesRef.current.lat,
        });
      }
      google.maps.event.removeListener(listener);
    });
    setMap(map);
  };

  const onUnmount = () => {
    setMap(undefined);
  };

  if (!isLoaded) {
    return null;
  }

  const hideAllCitiesMap = isWidgetActive && region?.id;
  return (
    <GoogleMap
      onLoad={onLoad}
      options={{
        disableDefaultUI: true,
        zoomControl: true,
        streetViewControl: false,
        clickableIcons: false,
        mapTypeControl: true,
        mapTypeControlOptions: {
          position: google.maps.ControlPosition.BOTTOM_LEFT,
          style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
        },
        /*styles: [
          {
            featureType: "road",
            stylers: [{ visibility: "off" }],
          },
          {
            featureType: "transit",
            stylers: [{ visibility: "off" }],
          },
          {
            featureType: "poi",
            stylers: [{ visibility: "off" }],
          },
          {
            featureType: "landscape",
            stylers: [{ visibility: "off" }],
          },
          {
            featureType: "poi.attraction",
            stylers: [{ visibility: "on" }],
          },
          {
            featureType: "road.local",
            stylers: [{ visibility: "on" }],
          },
          {
            featureType: "water",
            stylers: [{ color: "#D0DDEC" }],
          },
          {
            featureType: "landscape.natural",
            stylers: [{ visibility: "on" }, { color: "#EEF2F7" }],
          },
          {
            featureType: "landscape.man_made",
            stylers: [{ visibility: "on" }, { color: "#F7F8FA" }],
          },
          {
            featureType: "landscape.natural.landcover",
            stylers: [{ visibility: "on" }, { color: "#EEF2F7" }],
          },
        ],*/
      }}
      mapContainerStyle={{
        width: isMobileScreen ? width : "100vw",
        height: mapHeight,
      }}
      onUnmount={onUnmount}
      center={center}
      id="map"
      onZoomChanged={() => {
        setZoom((map as any)?.getZoom());
      }}
    >
      {map && (
        <>
          {!hideAllCitiesMap && (
            <>
              <AllCitiesMapState setMapBounds={setMapBounds} />
              {mapState === EMapState.ALL_CITIES && (
                <AllCitiesMapState setMapBounds={setMapBounds} />
              )}
            </>
          )}
          {mapState === EMapState.REGION && (
            <RegionMapState setMapBounds={setMapBounds} />
          )}
          {mapState === EMapState.CITY && (
            <CityMapState setMapBounds={setMapBounds} />
          )}
          {mapState === EMapState.BEACH && (
            <BeachMapState setMapBounds={setMapBounds} />
          )}
          {mapState === EMapState.SECTION && (
            <BeachMapStateForSection setMapBounds={setMapBounds} />
          )}
          {mapState === EMapState.SECTION &&
            configuration?.beachChairView === "grid" && (
              <SectionMapState setMapBounds={setMapBounds} zoom={zoom} />
            )}
          {mapState === EMapState.SECTION &&
            configuration?.beachChairView === "map" && (
              <SectionBeachChairsMapState
                setMapBounds={setMapBounds}
                zoom={zoom}
                setZoom={setZoom}
              />
            )}
          {mapState === EMapState.ROWS && (
            <RowMapState setMapBounds={setMapBounds} />
          )}
        </>
      )}
    </GoogleMap>
  );
});

export default memo(Maps);
